export {
  uniABI,
  erc20ABI,
  erc721ABI,
  sGLMRABI,
  rewardPoolABI,
  depositorABI,
  votingEscrowABI,
  stakingPoolABI,
  delegatorABI,
  rewardCollectorABI,
  escrowedRewardABI,
  sushiLPABI,
  mGLMRABI,
  distributeABI,
  distributeNFTABI,
} from "./abi";
export { contracts } from "./contracts";
//====== LOCAL =====//
// export const apiUrl = "http://localhost:21667/";
// export const socketIOUrl = "http://localhost:21667/";
// export const shareUrl = "http://localhost:3000/user/";
// export const twitterAuthUrl =
//   "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=LTBVcGJuTV9Scjh4Vkx3ak1mWWc6MTpjaQ&redirect_uri=http://localhost:3000/auth/Twitter&scope=tweet.read users.read follows.read offline.access&state=state&code_challenge=challenge&code_challenge_method=plain";
// export const discordAuthUrl =
//   "https://discord.com/oauth2/authorize?client_id=1039837175090532362&redirect_uri=http://localhost:3000/auth/Discord&response_type=code&scope=identify guilds.join guilds.members.read email guilds";
//===== DEV =====//
export const apiUrl = "https://api.metaform.xyz/dev/";
export const socketIOUrl = "https://api.metaform.xyz/";
export const shareUrl = "https://dev.metaform.xyz/user/";
export const twitterAuthUrl =
  "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=LTBVcGJuTV9Scjh4Vkx3ak1mWWc6MTpjaQ&redirect_uri=https://dev.metaform.xyz/auth/Twitter&scope=tweet.read users.read follows.read offline.access&state=state&code_challenge=challenge&code_challenge_method=plain";
export const discordAuthUrl =
  "https://discord.com/oauth2/authorize?client_id=1039837175090532362&redirect_uri=https://dev.metaform.xyz/auth/Discord&response_type=code&scope=identify guilds.join guilds.members.read email guilds";

export const tokenApiBaseUrl = "https://api.1inch.io/v4.0/1";
export const nativeAddress = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
const renderChain = (name, img) => {
  return (
    <span>
      <img src={require("assets/img/" + img)} className="smIcon" />
      <span>{name}</span>
    </span>
  );
};

export const chainList = [
  {
    label: renderChain("Ethereum", "eth.png"),
    value: 1,
    nativeToken: "ETH",
    nativeTokenIcon: "eth.png",
  },
  {
    label: renderChain("Polygon", "polygon.png"),
    value: 137,
    nativeToken: "MATIC",
    nativeTokenIcon: "polygon.png",
  },
  {
    label: renderChain("BSC", "bsc.png"),
    value: 56,
    nativeToken: "BNB",
    nativeTokenIcon: "bsc.png",
  },
  {
    label: renderChain("Avalanche", "avax.png"),
    value: 43114,
    nativeToken: "AVAX",
    nativeTokenIcon: "avax.png",
  },
  {
    label: renderChain("Optimism", "op.png"),
    value: 10,
    nativeToken: "ETH",
    nativeTokenIcon: "eth.png",
  },
  {
    label: renderChain("Arbitrum", "arbitrum.svg"),
    value: 42161,
    nativeToken: "ETH",
    nativeTokenIcon: "eth.png",
  },
  {
    label: renderChain("OKChain", "OKC.png"),
    value: 66,
    nativeToken: "OKT",
    nativeTokenIcon: "OKC.png",
  },
  {
    label: renderChain("Athens 2 Testnet", "zetalogo.png"),
    value: 7001,
    nativeToken: "aZETA",
    nativeTokenIcon: "zetalogo.png",
  },
  {
    label: renderChain("ThunderCore", "thundercore.png"),
    value: 108,
    nativeToken: "TT",
    nativeTokenIcon: "thundercore.png",
  },
];

export const aptosChainList = [
  {
    label: renderChain("Aptos", "aptos.png"),
    value: 999999,
    nativeToken: "APT",
    nativeTokenIcon: "aptos.png",
  },
];

export const everPayChainList = [
  {
    label: renderChain("EverPay", "arweave.png"),
    value: 888888,
    nativeToken: "AR",
    nativeTokenIcon: "arweave.png",
  },
];

export const otherChainList = [999999, 888888];

export const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#d2d0ee",
  "#b7f1a4",
  "#ae7d95",
  "#e194e6",
  "#696beb",
];

export const OPENSEA_TOP_LIST = [
  "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb", //CryptoPunk
  "0x05da517b1bf9999b7762eaefa8372341a1a47559", //KPR
  "0x60bb1e2aa1c9acafb4d34f71585d7e959f387769", //Art Gobbler
  "0x942bc2d3e7a589fe5bd4a5c6ef9727dfd82f5c8a", //Friendship Bracelets by Alexis André
  "0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7", //Meebits
  "0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b", //CloneX
  "0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258", //Otherdeed for Otherside
  "0x60e4d786628fea6478f785a6d7e704777c86a7c6", //Mutant Ape
  "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d", //Bored Ape
  "0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85", //ENS
];

export const OPENSEA_TOP_LIST_MAP = {
  "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb": "CryptoPunk",
  "0x05da517b1bf9999b7762eaefa8372341a1a47559": "KPR",
  "0x60bb1e2aa1c9acafb4d34f71585d7e959f387769": "Art Gobbler",
  "0x942bc2d3e7a589fe5bd4a5c6ef9727dfd82f5c8a":
    "Friendship Bracelets by Alexis André",
  "0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7": "Meebits",
  "0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b": "CloneX",
  "0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258": "Otherdeed for Otherside",
  "0x60e4d786628fea6478f785a6d7e704777c86a7c6": "Mutant Ape",
  "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d": "Bored Ape",
  "0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85": "ENS",
};

export const getWeb3ApiUrl = (chainId) => {
  let url = "";
  if (chainId == 1)
    url =
      "https://eth-mainnet.g.alchemy.com/v2/eg4gCkiDZxQ1X_D4vDk0Mz30lDwWQyVm";
  if (chainId == 10)
    url =
      "https://opt-mainnet.g.alchemy.com/v2/6QH3MDaqZ0JeUXYQ5NFm9i7vHIYDU-Dj";

  if (chainId == 56)
    url = "https://nd-609-228-616.p2pify.com/a345890fda1ebcac95e2fa60c4d1e60f";

  if (chainId == 137)
    url =
      "https://polygon-mainnet.g.alchemy.com/v2/TugKnx44-9srf-2Ldp0DhEpUA1tdsLpd";

  if (chainId == 42161)
    url =
      "https://arb-mainnet.g.alchemy.com/v2/JwsB8NeMcVDaWlzYdKAPiepRk0Q7mA8O";
  if (chainId == 43114)
    url =
      "https://avax-mainnet.gateway.pokt.network/v1/lb/63030c57b7dca700396842f8";
  if (chainId == 7001) url = "https://api.athens2.zetachain.com/evm";
  if (chainId == 108) url = "https://mainnet-rpc.thundercore.io";
  return url;
};

export const getAlchemyKey = (chainId) => {
  let key = "";
  if (chainId == 1) key = "eg4gCkiDZxQ1X_D4vDk0Mz30lDwWQyVm";
  if (chainId == 10) key = "6QH3MDaqZ0JeUXYQ5NFm9i7vHIYDU-Dj";
  if (chainId == 137) key = "TugKnx44-9srf-2Ldp0DhEpUA1tdsLpd";
  if (chainId == 42161) key = "JwsB8NeMcVDaWlzYdKAPiepRk0Q7mA8O";
  return key;
};

export const getAlchemyNetwork = (chainId) => {
  let network = "";
  if (chainId == 1) network = "eth-mainnet";
  if (chainId == 10) network = "opt-mainnet";
  if (chainId == 137) network = "polygon-mainnet";
  if (chainId == 42161) network = "arb-mainnet";
  return network;
};
