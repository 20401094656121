import React, { useEffect, useState } from "react";
// import { useFetchCreatorResponseData } from "features/form/redux/hooks";
import { useFetchCreatorRawResponseData } from "features/form/redux/hooks";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  OPENSEA_TOP_LIST,
  OPENSEA_TOP_LIST_MAP,
  apiUrl,
} from "features/configure";
import moment from "moment";
import Tag from "./components/Tag";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import Button from "@mui/material/Button";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
  gridSortedRowIdsSelector,
} from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useNetwork } from "wagmi";

import IconLogoCRM from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconLogoCRM";
import { exportCSV } from "features/helpers/utils";
import axios from "axios";

export default function OverviewResponseList({}) {
  // const { creatorResponseData } = useFetchCreatorResponseData();
  const {
    creatorRawResponseData,

    fetchCreatorRawResponseData,
    fetchCreatorRawResponseDataPending,
  } = useFetchCreatorRawResponseData();
  const { formId } = useParams();
  const [filterResponse, setFilterResponse] = useState([]);
  const [filterZero, setFilterZero] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = React.useState(20);
  const [dataMode, setDataMode] = React.useState("server");
  const [apiRef, setApiRef] = React.useState();
  const [rows, setRows] = useState([]);
  const [rowsWithTags, setRowsWithTags] = useState([]);
  const [cols, setCols] = useState([]);
  const { chains } = useNetwork();
  const paymentFormId = "648abd587ce0a03c96c641ab";
  const blockchain = _.get(
    creatorRawResponseData,
    `[${formId}].form.blockchain`,
    "evm"
  );
  const NFT_LIST = [
    {
      name: "BAYC",
      address: "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d",
    },
    {
      name: "MAYC",
      address: "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
    },
    {
      name: "Azuki",
      address: "0xed5af388653567af2f388e6224dc7c4b3241c544",
    },
    {
      name: "CloneX",
      address: "0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b",
    },
  ];
  useEffect(() => {
    fetchCreatorRawResponseData({
      formId,
      page,
      pageSize,
      filterZero: filterZero ? 1 : 0,
    });
  }, [formId, page, pageSize, filterZero]);

  const setPageSizeFunc = (pageSize) => {
    setPage(0);
    setPageSize(pageSize);
  };

  const getAddressLink = (value) => {
    if (blockchain == "everPay")
      return `https://scan.everpay.io/account/${value}`;
    if (blockchain == "aptos") return `https://aptscan.ai/accounts/${value}`;
    return `https://etherscan.io/address/${value}`;
  };

  const renderToggleZeroButton = () => {
    if (!creatorRawResponseData || !creatorRawResponseData[formId]) return;
    return (
      <div>
        <span style={{ fontWeight: 500, marginRight: 10 }}>
          Filter Non-Active Addresses
        </span>
        <CustomSwitch
          checked={filterZero}
          onChange={(e) => {
            setFilterZero(e.target.checked);
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    if (!creatorRawResponseData || !creatorRawResponseData[formId]) return;

    let totalCount = _.get(
      creatorRawResponseData[formId],
      "responses[0].metadata[0].total",
      0
    );
    let dataCount = _.get(
      creatorRawResponseData[formId],
      "responses[0].data",
      []
    );
    dataCount = dataCount.length;

    if (apiRef) {
      if (totalCount == dataCount) {
        const handleExport = (options) =>
          apiRef.current.exportDataAsCsv(options);
        const getUnfilteredRows = ({ apiRef }) =>
          gridSortedRowIdsSelector(apiRef);

        setTimeout(() => {
          handleExport({ getRowsToExport: getUnfilteredRows, delimiter: ";" });
        }, 1000);
      }
    }

    // if (!creatorResponseData || !creatorResponseData[formId]) return;
    const isOkx =
      _.get(creatorRawResponseData, `[${formId}].form.chainId`) == 66;
    let walletValueCols = [
      {
        field: "walletValue",
        headerName: "Wallet Value (All)",
        width: 200,
        valueFormatter: ({ value }) =>
          _.get(value, "value.walletValue", 0).toFixed(0),
        renderCell: (params) => {
          return (
            <div>
              <div>{_.get(params, "value.walletValue", 0).toFixed(0)}</div>
            </div>
          );
        },
      },
    ];

    let onChainCols = [
      {
        field: "onChainValue",
        headerName: "On-Chain Value (All)",
        width: 200,
        valueFormatter: ({ value }) =>
          _.get(value, "value.onChainValue", 0).toFixed(0),
        renderCell: (params) => {
          return (
            <div>
              <div>{_.get(params, "value.onChainValue", 0).toFixed(0)}</div>
            </div>
          );
        },
      },
    ];

    if (isOkx) {
      walletValueCols = [
        {
          field: "walletValue_OKC",
          headerName: "Wallet Value (OKC)",
          width: 200,

          renderCell: (params) => {
            if (!params.value) return 0;
            if (isOkx) {
              const okValue = parseFloat(
                _.get(
                  _.find(params.value.walletValueList, { chainId: 66 }),
                  "usd_value",
                  0
                )
              ).toFixed(0);
              return (
                <div>
                  <div>{okValue}</div>
                </div>
              );
            }
            return params.value.walletValue;
          },
        },
        {
          field: "walletValue",
          headerName: "Wallet Value (Other)",
          width: 200,
          renderCell: (params) => {
            if (!params.value) return 0;
            if (isOkx) {
              const okValue = parseFloat(
                _.get(
                  _.find(params.value.walletValueList, { chainId: 66 }),
                  "usd_value",
                  0
                )
              );
              return (
                <div>
                  <div>
                    {parseFloat(
                      (params.value.walletValue || 0) - okValue
                    ).toFixed(0)}
                  </div>
                </div>
              );
            }
            return params.value.walletValue;
          },
        },
      ];

      onChainCols = [
        {
          field: "onChainValue_OKC",
          headerName: "On-Chain Value (OKC)",
          width: 200,
          renderCell: (params) => {
            if (!params.value) return 0;
            if (isOkx) {
              const okValue = parseFloat(
                _.get(
                  _.find(params.value.onChainList, { chain: "okt" }),
                  "net_usd_value",
                  0
                )
              ).toFixed(0);
              return (
                <div>
                  <div>{okValue}</div>
                </div>
              );
            }
            return params.value.onChainValue;
          },
        },
        {
          field: "onChainValue",
          headerName: "On-Chain Value (Other)",
          width: 200,
          renderCell: (params) => {
            if (!params.value) return 0;
            if (isOkx) {
              const okValue = parseFloat(
                _.get(
                  _.find(params.value.onChainList, { chain: "okt" }),
                  "net_usd_value",
                  0
                )
              );
              return (
                <div>
                  <div>
                    {parseFloat(
                      (params.value.onChainValue || 0) - okValue
                    ).toFixed(0)}
                  </div>
                </div>
              );
            }
            return params.value.onChainValue;
          },
        },
      ];
    }

    const renderQuestionCell = (q) => {
      if (q.type == "payment") {
        return {
          field: q._id,
          headerName: q.title,
          width: 550,
          renderCell: (params) => {
            if (!params.value) return "";
            if (q.type == "payment") {
              return (
                <div>
                  {params.value} {q.payment.symbol}
                </div>
              );
            }
            return params.value;
          },
        };
      }
      if (q.type == "file") {
        return {
          field: q._id,
          headerName: q.title,
          width: 300,
          renderCell: (params) => {
            const value = params.value;
            if (!value) return "";
            if (Array.isArray(value)) {
              return (
                <div>
                  {_.map(value, (v) => {
                    const urls = v.split("/");
                    return (
                      <div>
                        <a href={v} target="_blank">
                          {urls[urls.length - 1]}
                        </a>
                      </div>
                    );
                  })}
                </div>
              );
            }
            const urls = value.split("/");
            return (
              <a href={value} target="_blank">
                {urls[urls.length - 1]}
              </a>
            );
          },
        };
      }
      return { field: q._id, headerName: q.title, width: 300 };
    };

    let columns = _.concat(
      _.concat(
        [
          {
            field: "id",
            headerName: "ID",
            width: 90,
          },
          {
            field: "address",
            headerName: "Address",
            width: 440,
            renderCell: (params) => (
              <a
                href={getAddressLink(params.value)}
                target="_blank"
                style={{ margin: 3 }}
              >
                {params.value}
              </a>
            ),
          },
          {
            field: "tags",
            headerName: "# Tags",
            width: 200,
            valueFormatter: ({ value }) => value.map((tag, i) => tag.value),
            renderCell: (params) => {
              return params.value.map((tag, i) => (
                <Tag
                  key={i}
                  label={tag.value}
                  color={tag.color}
                  tooltip={tag.tooltip}
                  desc={tag.desc}
                />
              ));
            },
            cellClassName: "tags-cell",
            filterOperators: tagsOnlyOperators,
          },
          {
            field: "addressFirstTransactionDate",
            headerName: "Address Activated At",
            width: 200,
          },
          {
            field: "addressLatestTransactionDate",
            headerName: "Address Last Active At",
            width: 200,
          },
          {
            field: "nonce",
            headerName: "Address Tx Count",
            width: 200,
          },
        ],
        walletValueCols,
        onChainCols,
        [
          {
            field: "nftValue",
            headerName: "NFT Value (All)",
            width: 200,
          },
          {
            field: "nftCount",
            headerName: "NFT Count (All)",
            width: 200,
          },
          {
            field: "date",
            headerName: "Date",
            width: 180,
          },
        ],
        _.map(creatorRawResponseData[formId].questions, (q, i) =>
          renderQuestionCell(q)
        ),
        _.map(creatorRawResponseData[formId].deletedQuestions, (q, i) =>
          renderQuestionCell(q)
        )
      )
    );
    if (formId == paymentFormId) {
      columns = _.concat(
        columns,
        {
          field: "airdrop",
          headerName: "Airdrop",
          width: 550,
          renderCell: (params) => {
            const chain = _.find(chains, { id: 137 });
            return (
              <a
                target="_blank"
                href={chain?.blockExplorers.default.url + "/tx/" + params.value}
              >
                {params.value}
              </a>
            );
          },
        },
        NFT_LIST.map((nft) => {
          return {
            field: nft.name,
            headerName: nft.name,
            width: 80,
          };
        })
      );
    }
    if (formId == "636e21428bbbb9f25ce898da") {
      columns.splice(2, 0, {
        field: "isPaid",
        headerName: "Paid",
        width: 200,
      });

      columns.splice(3, 0, {
        field: "imported",
        headerName: "Imported",
        width: 200,
      });
    }
    setCols(columns);
  }, [apiRef, creatorRawResponseData, formId]);

  useEffect(() => {
    if (filterZero && creatorRawResponseData) {
      setFilterResponse(
        _.filter(
          _.get(creatorRawResponseData, `[${formId}].responses`, []),
          (r) => {
            return r.responseInfo && r.responseInfo.addressNonce > 0;
          }
        )
      );
    } else if (!filterZero && creatorRawResponseData) {
      setFilterResponse(
        _.get(creatorRawResponseData, `[${formId}].responses`, [])
      );
    }
  }, [creatorRawResponseData, filterZero]);

  useEffect(() => {
    if (!creatorRawResponseData || !creatorRawResponseData[formId]) return;

    let i = page * pageSize + 1;
    let responses = _.get(
      creatorRawResponseData,
      `[${formId}].responses[0].data`,
      []
    );
    let rows = _.reduce(
      responses,
      (result, d, address) => {
        let rowData = {};
        _.map(d.responses, (re) => {
          if (!re) return "";
          let r = _.get(re, "response");

          if (re.otherText) {
            r = _.replace(r, "Other", "Other=" + re.otherText);
          }
          rowData[re.question] = r;
        });
        let row = d;
        const responseInfo = _.get(row, "responseInfo");
        let data = {
          id: i,
          address: d.address,
          tags: ["test", "Defi"],
          addressFirstTransactionDate: _.get(
            row,
            "responseInfo.addressFirstTransactionDate"
          )
            ? moment(
                _.get(row, "responseInfo.addressFirstTransactionDate")
              ).format("YYYY/MM/DD HH:mm:ss")
            : "N/A",
          addressAge: _.get(row, "responseInfo.addressFirstTransactionDate")
            ? moment().diff(
                moment(_.get(row, "responseInfo.addressFirstTransactionDate")),
                "years",
                true
              )
            : "N/A",
          addressLatestTransactionDate: _.get(
            row,
            "responseInfo.addressLatestTransactionDate"
          )
            ? moment(
                _.get(row, "responseInfo.addressLatestTransactionDate")
              ).format("YYYY/MM/DD HH:mm:ss")
            : "N/A",
          nonce: _.get(row, "responseInfo.addressNonce")
            ? _.get(row, "responseInfo.addressNonce")
            : 0,
          nftValue: _.get(row, "responseInfo.nftValue")
            ? _.get(row, "responseInfo.nftValue")
            : 0,
          onChainValue: responseInfo,
          onChainValue_OKC: responseInfo,
          walletValue: responseInfo,
          walletValue_OKC: responseInfo,
          nftAddresses: _.get(row, "responseInfo.nftList")
            ? _.map(_.get(row, "responseInfo.nftList"), "address")
            : 0,
          nftCount: _.get(row, "responseInfo.nftList")
            ? _.get(row, "responseInfo.nftList").length
            : 0,
          date: _.get(row, "responseInfo.created")
            ? moment(_.get(row, "responseInfo.created")).format(
                "YYYY/MM/DD HH:mm:ss"
              )
            : "N/A",
          isPaid: _.get(row, "responseInfo.isPaid")
            ? _.get(row, "responseInfo.isPaid")
            : false,
          ...rowData,
          imported: _.get(row, "responseInfo.imported")
            ? _.get(row, "responseInfo.imported")
            : false,
          ...rowData,
        };

        if (formId == paymentFormId) {
          data["airdrop"] = responseInfo.airdrop;
          NFT_LIST.map((item) => {
            const count = _.reduce(
              responseInfo.nftList,
              (sum, nft) => {
                if (_.toLower(nft.address) == _.toLower(item.address))
                  sum += nft.amount;
                return sum;
              },
              0
            );
            data[item.name] = count;
          });
        }

        result.push(data);
        i += 1;
        return result;
      },
      []
    );
    setRows(rows);
  }, [creatorRawResponseData, page, pageSize, formId]);

  const walletValueTags = ["Crypto Whale", "Crypto Dolphin", "Crypto Shrimp"];
  const onchainValueTags = [
    "OnChain Whale",
    "OnChain Dolphin",
    "OnChain Shrimp",
  ];
  const nftValueTags = ["NFT Whale", "NFT Dolphin", "NFT Shrimp"];
  const nftCountTags = ["NFT 50+", "NFT 10+"];
  const addressAgeTags = ["Crypto OG", "Crypto Regular", "Crypto Newbie"];
  const specialTags = ["OpenSea Top Holder"];

  let allTags = walletValueTags.concat(
    onchainValueTags,
    nftValueTags,
    nftCountTags,
    addressAgeTags,
    specialTags
  );

  function TagFilterInputValue(props) {
    const { item, applyValue, focusElementRef } = props;

    const tagRef = React.useRef(null);
    React.useImperativeHandle(focusElementRef, () => ({
      focus: () => {
        tagRef.current
          .querySelector(`input[value="${item.value || ""}"]`)
          .focus();
      },
    }));

    const handleFilterChange = (event, newValue) => {
      applyValue({ ...item, value: newValue.props.value });
    };

    return (
      <>
        <InputLabel variant="standard" shrink={true} disableAnimation={true}>
          Value
        </InputLabel>
        <Select
          value={item.value ? item.value : ""}
          label="Tags"
          onChange={handleFilterChange}
          ref={tagRef}
        >
          {allTags.map((tag) => (
            <MenuItem value={tag}>{tag}</MenuItem>
          ))}
        </Select>
      </>
    );
  }

  const tagsOnlyOperators = [
    {
      label: "Includes",
      value: "includes",
      getApplyFilterFn: (filterItem) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }

        return (params) => {
          let tags = _.map(params.value, "value");
          return tags.includes(filterItem.value);
        };
      },
      InputComponent: TagFilterInputValue,
      InputComponentProps: { type: "string" },
    },
  ];

  useEffect(() => {
    let rowsData = [];
    rowsData = _.map(rows, (row, index) => {
      let result = _.clone(row);

      result.tags = [];
      // wallet value
      if (row.walletValue >= 200000) {
        result.tags.push({
          value: "Whale (Token)",
          color: "#3876D2",
          tooltip: "whale.svg",
          desc: "Token in wallet value >$200k (NFT not included)",
        });
      } else if (row.walletValue >= 10000 && row.walletValue < 200000) {
        result.tags.push({
          value: "Dolphin (Token)",
          color: "#33AC63",
          tooltip: "dolphin.svg",
          desc: "Token in wallet value between $10k~200k (NFT not included)",
        });
      } else if (row.walletValue > 0 && row.walletValue < 10000) {
        result.tags.push({
          value: "Shrimp (Token)",
          color: "#F56F6F",
          tooltip: "shrimp.svg",
          desc: "Token in wallet value <$10k (NFT not included)",
        });
      }
      // onchain value
      if (row.onChainValue >= 100000) {
        result.tags.push({
          value: "Whale (SC)",
          color: "#3876D2",
          tooltip: "whale.svg",
          desc: "Smart Contract TVL >$100k",
        });
      } else if (row.onChainValue >= 10000 && row.onChainValue < 100000) {
        result.tags.push({
          value: "Dolphin (SC)",
          color: "#33AC63",
          tooltip: "dolphin.svg",
          desc: "Smart Contract TVL between $10k~100k",
        });
      } else if (row.onChainValue > 0 && row.onChainValue < 10000) {
        result.tags.push({
          value: "Shrimp (SC)",
          color: "#F56F6F",
          tooltip: "shrimp.svg",
          desc: "Smart Contract TVL <$10k",
        });
      }
      // nft value
      if (row.nftValue >= 200000) {
        result.tags.push({
          value: "Whale ($NFT)",
          color: "#3876D2",
          tooltip: "whale.svg",
          desc: "NFT in wallet value >$200k",
        });
      } else if (row.nftValue >= 10000 && row.nftValue < 200000) {
        result.tags.push({
          value: "Dolphin ($NFT)",
          color: "#33AC63",
          tooltip: "dolphin.svg",
          desc: "NFT in wallet value between $10k~200k",
        });
      } else if (row.nftValue > 0 && row.nftValue < 10000) {
        result.tags.push({
          value: "Shrimp ($NFT)",
          color: "#F56F6F",
          tooltip: "shrimp.svg",
          desc: "NFT in wallet value <$10k",
        });
      }

      //nft count
      if (row.nftCount >= 50) {
        result.tags.push({
          value: "#NFT 50+",
          color: "#3FDAAB",
          tooltip: "whale.svg",
          desc: "NFT in wallet # >50",
        });
      } else if (row.nftCount >= 10 && row.nftCount < 50) {
        result.tags.push({
          value: "#NFT 10~50",
          color: "#F56F6F",
          tooltip: "dolphin.svg",
          desc: "NFT in wallet # between 10~50",
        });
      } else if (row.nftCount >= 1 && row.nftCount < 10) {
        result.tags.push({
          value: "#NFT < 10",
          color: "#F56F6F",
          tooltip: "shrimp.svg",
          desc: "NFT in wallet # <10",
        });
      }

      let isOpenseaTopHolder = false;
      let ownedNFTs = [];
      _.each(row.nftAddresses, (address) => {
        if (OPENSEA_TOP_LIST.includes(address)) {
          isOpenseaTopHolder = true;
          ownedNFTs.push(OPENSEA_TOP_LIST_MAP[address]);
        }
      });

      //address status
      if (row.addressAge >= 3) {
        result.tags.push({
          value: "Crypto OG",
          color: "#E84029",
          tooltip: "OG.svg",
          desc: "Address Creation > 3 years",
        });
      } else if (row.addressAge >= 1 && row.addressAge < 3) {
        result.tags.push({
          value: "Crypto Regular",
          color: "#61D0FF",
          tooltip: "normal.svg",
          desc: "Address Creation between 1~3 years",
        });
      } else if (row.addressAge != "N/A") {
        result.tags.push({
          value: "Crypto Newbie",
          color: "#F9C200",
          tooltip: "newbie.svg",
          desc: "Address Creation <1 year",
        });
      }

      if (isOpenseaTopHolder) {
        result.tags.push({
          value: specialTags[0],
          color: "#696BEB",
          tooltip: "crown.svg",
          desc: ownedNFTs.toString(),
        });
      }

      return result;
    });
    setRowsWithTags(rowsData);
  }, [rows]);

  const CustomToolbar = () => {
    const apiRef = useGridApiContext();

    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ delimiter: ";" }} />
        <Button
          onClick={() => {
            fetchCreatorRawResponseData({
              formId,
              page: 0,
              pageSize: pageSize,
              filterZero: filterZero ? 1 : 0,
              dump: 1,
            });
            setApiRef(apiRef);
            setPage(0);
          }}
        >
          Export All
        </Button>
        <Button
          onClick={async () => {
            try {
              const result = await axios.get(
                apiUrl +
                  `api/forms/${formId}/responses/metadesk?filterZero=${filterZero}`
              );
              exportCSV(result.data);
            } catch (e) {
              console.log(e);
              alert(e);
            }
          }}
        >
          <IconLogoCRM style={{ marginRight: 5, width: 18 }} /> Export as
          MetaCRM format
        </Button>
      </GridToolbarContainer>
    );
  };

  const renderAllRows = () => {
    if (!creatorRawResponseData || !creatorRawResponseData[formId]) return;
    let totalCount = _.get(
      creatorRawResponseData[formId],
      "responses[0].metadata[0].total",
      0
    );

    return (
      <div className="card">
        <div style={{ margin: "20px auto" }}>
          <div style={{ fontSize: 25, fontWeight: 900 }}>Responses</div>
          <div style={{ marginTop: 20, marginBottom: 5 }}>
            {totalCount} Users
          </div>
          <div style={{ height: 800, width: "100%" }}>
            <DataGrid
              keepNonExistentRowsSelected
              rows={rowsWithTags}
              columns={cols}
              getRowHeight={() => "auto"}
              checkboxSelection
              disableSelectionOnClick
              getCellClassName={(params) => "tableCell"}
              getRowClassName={(params) => `tableValue`}
              components={{
                Toolbar: CustomToolbar,
              }}
              rowsPerPageOptions={[20, 50, 100]}
              rowCount={totalCount}
              loading={fetchCreatorRawResponseDataPending}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode={dataMode}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => {
                setPageSizeFunc(newPageSize);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderToggleZeroButton()}
      {renderAllRows()}
    </div>
  );
}
