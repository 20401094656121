import React, { useEffect, useState } from "react";
import Reward from "./Reward";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { Navigate } from "react-router-dom";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CreatorResponseList from "./CreatorResponseList";
import Share from "./components/Share";
import { shareUrl } from "features/configure";
import { Loading } from "features/common";
import LandingHeader from "features/home/components/LandingHeader";
import FillForm from "./FillForm";
import { useFetchFormData } from "features/form/redux/hooks";
import { useConnectWallet } from "features/home/redux/hooks";
import _ from "lodash";
import { Outlet } from "react-router-dom";
import CustomListItem from "components/CustomListItem/CustomListItem";
import { useFetchCreatorResponseData } from "features/form/redux/hooks";
import OverviewResponseList from "./OverviewResponseList";
import SocialIcons from "features/home/components/socialIcons/SocialIcons";
import SideMenu from "components/SideMenu/SideMenu";

const typeMap = {
  preview: 0,
  share: 1,
  responses: 2,
  reward: 4,
  distribute: 5,
  distributed: 6,
};

const FormInfo = ({}) => {
  const { formId } = useParams();
  const { data, fetchFormData } = useFetchFormData();
  const { fetchCreatorResponseData, fetchCreatorResponseDataPending } =
    useFetchCreatorResponseData();
  const location = useLocation();
  const { auth, checkAuth, userData } = useConnectWallet();
  const formData = _.get(data[formId], "form");
  const navigate = useNavigate();
  const address = _.get(userData, "address");
  const rewardData = _.get(data, `[${formId}].reward`, {});
  const status = _.get(rewardData, "status");

  useEffect(() => {
    fetchFormData({ address: _.get(userData, "address"), formId });
    fetchCreatorResponseData({
      formId,
      address,
    });
  }, [formId, auth]);

  const renderMenuItem = (value, icon, title, disabled) => {
    const to = `creator/${formId}/${value}`;
    return (
      <CustomListItem
        formPage
        right
        disabled={disabled}
        selected={location.pathname == "/" + to}
        label={
          <div className="row">
            <span style={{ minWidth: 5 }}></span>
            <img src={require(`assets/img/${icon}.svg`)} className="smIcon" />
            {title}
          </div>
        }
        onClick={() => {
          if (!disabled) {
            navigate(value);
          }
        }}
      />
    );
  };
  const renderMenuContent = () => {
    return (
      <>
        <div style={{ textAlign: "right" }}>
          <IconButton
            onClick={() => {
              navigate("/creator");
            }}
            style={{ padding: 10 }}
          >
            <img src={require("assets/img/back.png")} className="smIcon" />
            <span style={{ fontSize: 14, fontWeight: 500, color: "#B6B6B6" }}>
              Back
            </span>
          </IconButton>
        </div>
        <CustomListItem
          header
          label={
            <div style={{ fontWeight: 700, fontSize: 20 }}>
              {_.get(formData, "header.title")}
              <img
                src={require("assets/img/headerDeco.svg").default}
                className="icon"
                style={{ marginLeft: 5 }}
              />
            </div>
          }
        />
        {renderMenuItem("preview", "previewIcon", "Preview")}
        {renderMenuItem("share", "shareIcon", "Share")}
        <CustomListItem header label={"Responses"} />
        {renderMenuItem("responses", "single", "Survey Results")}
        {renderMenuItem("dataDashboard", "overview", "Data Dashboard")}
        <CustomListItem header label={"Settings"} />
        {renderMenuItem("rewardSettings", "gift2", "Reward Settings")}
        {renderMenuItem(
          "distributionSettings",
          "distribute",
          "Distribution Settings",
          !status
        )}
        {renderMenuItem(
          "distributionRecord",
          "distributedAddress",
          "Distribution Record",
          status !== "distributed"
        )}
        <div style={{ position: "absolute", bottom: 0, padding: "20px 20px" }}>
          <SocialIcons />
        </div>
      </>
    );
  };

  return (
    <div>
      {/* {fetchCreatorResponseDataPending && <Loading open={true} />} */}

      {auth && (
        <div style={{ display: "flex" }}>
          <SideMenu content={renderMenuContent()} />
          <div className="container">
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
};

export default FormInfo;
