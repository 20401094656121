import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button";
import { useConnectWallet } from "features/home/redux/hooks";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import LandingHeader from "./components/LandingHeader";
import { Link, useNavigate } from "react-router-dom";
import SocialIcons from "./components/socialIcons/SocialIcons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CustomTabs from "components/CustomTabs/Tabs";
import _ from "lodash";
import ReactGA from "react-ga4";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";
import LandingOurPartners from "./components/LandingOurPartners/LandingOurPartners";
import FooterEmail from "./components/Footer/FooterEmail";
import { ReactComponent as EmailIcon } from "assets/img/ic_email.svg";
import {
  StyledLink,
  StyledLinkMobile,
  StyledLinkPrivacyPolicyMobile,
  StyledFooterFixedWidthContainer,
} from "./Landing.styles";
import { Outlet } from "react-router-dom";

const Landing = () => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div style={{ overflow: "hidden" }}>
      <Outlet />

      {computer ? (
        <div
          style={{
            background: "#383538",
            padding: computer ? "31px 15px" : 15,
          }}
        >
          <div style={{ maxWidth: 1200, margin: "0px auto" }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: 31, alignItems: "center" }}
            >
              <Grid item xs={12}>
                <div style={{ color: "white" }}>
                  <div>
                    <img
                      src={require(`assets/img/footerLogo.svg`).default}
                      style={{ height: 30 }}
                    />
                  </div>
                </div>
              </Grid>

              <StyledFooterFixedWidthContainer flexStart item>
                <StyledLink
                  target="_blank"
                  to="https://metacrm.gitbook.io/metacrm/metaform"
                >
                  Documentation
                </StyledLink>
                <StyledLink to="/privacy-policy">Privacy policy</StyledLink>
              </StyledFooterFixedWidthContainer>

              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <FooterEmail Icon={EmailIcon} computer={computer} />
                <StyledLink
                  to="https://metaform.xyz/user/651449e47e72ae2219cbaa94"
                  target="_blank"
                  computer={computer}
                >
                  Partnership
                </StyledLink>
              </Grid>
              <StyledFooterFixedWidthContainer item>
                <SocialIcons white={true} />
              </StyledFooterFixedWidthContainer>
            </Grid>
            <div
              style={{
                color: "rgba(255,255,255,0.4)",
                textAlign: "center",
                borderTop: "0.5px solid rgba(255,255,255,0.2)",
                paddingTop: 16,
              }}
            >
              Copyright© 2022 - MetaCRM. All Rights Reserved
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              background: "#383538",
              height: 56,
            }}
          >
            <Grid item style={{ width: 80 }}></Grid>
            <Grid item>
              <img
                src={require(`assets/img/footerLogo.svg`).default}
                style={{ height: 26 }}
              />
            </Grid>
            <Grid item>
              <SocialIcons white="true" />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default Landing;
